import { useState } from "react";
import React from "react";
import { Icon } from "@iconify/react";
import revampData from '../../data/revampData.json';
const FaqPage = () => {
  const [openIndices, setOpenIndices] = useState([]);
  const planTermsData = (revampData?.data?.listFaqsData?.data || []).sort((a, b) => Number(a.orderNo) - Number(b.orderNo));
  const faqs = planTermsData.map(faq => ({
    question: faq.title,
    topText: faq.topText || [],
    listItems: faq.listItems || [],
    bottomText: faq.bottomText || []
  }));
  const toggleAccordion = index => {
    setOpenIndices(prev => prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]);
  };
  return <div className="px-sm-5" data-sentry-component="FaqPage" data-sentry-source-file="FaqPage.jsx">
            <div className="faq-container">
                <h2 className="faq-header text-center">Frequently Asked Questions</h2>
                <div className="faq-list">
                    {faqs.map((faq, index) => <div key={index} className="faq-item">
                            <div className="faq-question" onClick={() => toggleAccordion(index)}>
                                {faq.question}
                                <span className="faq-icon">
                                    <Icon icon={openIndices.includes(index) ? "fe:arrow-up" : "fe:arrow-down"} />
                                </span>
                            </div>
                            {openIndices.includes(index) && <div className="faq-answer">
                                    {faq.topText.map((text, i) => <p key={`top-${i}`}>{text}</p>)}

                                    {faq.listItems.length > 0 && <ul>
                                            {faq.listItems.map((item, i) => <li key={`list-${i}`}>{item}</li>)}
                                        </ul>}

                                    {faq.bottomText.map((text, i) => <p key={`bottom-${i}`}>{text}</p>)}
                                </div>}
                        </div>)}
                </div>

                <style>{`
                    .faq-container {
                        margin: auto;
                        padding-bottom: 48px;
                    }

                    .faq-header {
                        text-align: center;
                        font-weight: bold;
                        color: #001234;
                        padding: 40px;
                        font-size: 36px;
                    }

                    .faq-item {
                        border-bottom: 1px solid #ddd;
                    }

                    .faq-question {
                        padding: 15px;
                        font-size: 20px;
                        font-weight: 500;
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                    }

                    .faq-answer {
                        padding: 15px;
                        font-size: 18px;
                        color: #475467;
                        font-weight: 400;
                    }

                    .faq-icon {
                        font-size: 20px;
                    }

                    .faq-question:hover {
                        background-color: #f9f9f9;
                    }

                    .faq-answer ul {
                        padding-left: 20px;
                    }

                    .faq-answer ul li {
                        margin-bottom: 5px;
                    }
                `}</style>
            </div>
        </div>;
};
export default FaqPage;